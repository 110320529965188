.reality-wrapper {
  position: absolute;
  overflow: hidden;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
}
